import React from 'react'
import PropTypes from 'prop-types'
import './HeaderSpacer.scss'

const HeaderSpacer = ({ children }) => (
  <div className="row">
    <div className="d-flex HeaderSpacer justify-content-center w-100">
      <div className="d-flex justify-content-center w-75">
        {children}
      </div>
    </div>
  </div>
)

HeaderSpacer.propTypes = {
  children: PropTypes.node.isRequired
}

export default HeaderSpacer
