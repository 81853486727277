import React from 'react'
import BuiltInFeature from './BuiltInFeature'
import DouaneConfiguratorImage from '../images/DouaneConfigurator.png'
import { Trans } from 'react-i18next'

const DouaneConfigurator = () => (
  <BuiltInFeature
    figureAlt="Douane Configurator window"
    figureSrc={DouaneConfiguratorImage}
    caption={(
      <React.Fragment>
        <h3>
          <Trans i18nKey="DouaneConfiguratorTitle">
            A single place to control Douane
          </Trans>
        </h3>
        <p>
          <Trans i18nKey="DouaneConfiguratorControlPanel">
            This is the Douane control panel.
          </Trans>
        </p>
        <p>
          <Trans i18nKey="DouaneConfiguratorItAllowsYouTo">
            It allows you to:
          </Trans>
        </p>
        <ul>
          <li>
            <Trans i18nKey="DouaneConfiguratorStartStopDeamon">
              Start/Stop the firewall
            </Trans>
          </li>
          <li>
            <Trans i18nKey="DouaneConfiguratorEnableDisableAutostart">
              Enable/Disable the autostart feature
            </Trans>
          </li>
          <li>
            <Trans i18nKey="DouaneConfiguratorSeeAndDeleteRules">
              See and delete created rules
            </Trans>
          </li>
        </ul>
      </React.Fragment>
    )}
  />
)

export default DouaneConfigurator
