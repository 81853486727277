const FrenchTranslation = {
  // BuiltInFeaturesAvailable
  BuiltInFeaturesAvailableTitle: 'Les fonctionnalités intégrées de Douane',
  // Compatibility
  CompatibilityDescription: 'Douane fonctionne sur toutes les distributions GNU/Linux ayant une version de noyau 3 ou supérieure, et il nécessite Python 3 pour le panneau de configuration, ainsi que de GTK 3 pour la boîte de dialogue et le panneau de configuration.',
  CompatibilityFindCompleteList: 'Vous pouvez trouver la liste complète des dépendances Douane sur <1>la page Wiki Douane</1>.',
  CompatibilityTitle: 'Compatibilité',
  // DouaneConfigurator
  DouaneConfiguratorControlPanel: 'Voici le panneau de contrôle de Douane.',
  DouaneConfiguratorEnableDisableAutostart: 'Active/désactiver le démarrage automatique',
  DouaneConfiguratorItAllowsYouTo: 'Il vous permet de:',
  DouaneConfiguratorSeeAndDeleteRules: 'Voir et supprimer les règles créées',
  DouaneConfiguratorStartStopDeamon: 'Démarrer/arrêter le pare-feu',
  DouaneConfiguratorTitle: 'Un seul endroit pour contrôler Douane',
  // DouaneDialog
  DouaneDialogAppAccessingTheInternet: 'Dès que vous avez accès à un réseau, les applications essaient de transmettre toutes sortes d\'informations.',
  DouaneDialogHowDoesItWork: 'Douane bloquera tout le trafic inconnu et vous permettra de décider si vous l\'autorisez ou non grâce à cette boîte de dialogue.',
  DouaneDialogClickToAddRule: 'Cliquez sur les boutons <1>Autoriser</1> ou <1>Refuser</1> pour créer la règle souhaitée.',
  DouaneDialogTitle: 'Simple comme répondre à une question',
  DouaneDialogUnexpecedTrafic: 'Au travers de votre trafic (emails, réseaux sociaux, vidéos en ligne, ...) vous découvrirez des activitées de réseau auxquelles vous ne vous attendiez pas.',
  // Download
  DownloadAnotherReason: 'Une autre raison de la faible popularité du projet est parcequ\'il y a <1>ce méchant bogue qui gêle le noyau</1>, mais c\'est la priorité absolue du projet.',
  DownloadCurrentVersion: 'La version actuelle est {{version}}.',
  DownloadDouaneInstaller: 'douane-installer (Conseillée)',
  DownloadDouaneInstallerComesIn: 'C\'est pourquoi j\'ai créé <1>douane-installer</1> qui automatise le téléchargement, la compilation et l\'installation de Douane sur votre machine.',
  DownloadDouaneInstallerDownloadItNow: 'Téléchargez-le et exécutez-le comme décrit dans <1>le fichier README.md du projet</1>.',
  DownloadDouaneInstallerFigureCaption: 'douane-installer automatise le processus d\'installation de Douane.',
  DownloadDouaneInstallerIsFlexible: '<1>L\'architecture de Douane</1> est un puzzle, exprès, afin de permettre à Douane de s\'intégrer dans le gigantesque écosystème de Linux.',
  DownloadDouaneInstallerThePriceOfFlexibility: 'Mais la flexibilité s\'accompagne de complexité et rend Douane difficile à installer pour de nombreuses personnes.',
  DownloadIAmNotDoingThePackaging: 'Empaqueter une application prend beaucoup de temps, j\'ai donc décidé de ne pas m\'occuper de cette partie, même si je le faisais dans le passé pour le format de paquet Debian.',
  DownloadFindDebianPackageConfigFiles: 'Vous pouvez trouver des fichiers <1>ici</1>.',
  DownloadFollowupOnIssue: 'Vous pouvez suivre la résolution de bogue depuis <1>ce ticket</1>.',
  DownloadNoPackagesYet: 'Malheureusement, aucun paquet n\'existe dans aucun format de distribution.',
  DownloadSeeRoadmap: 'Jetez un œil à <1>la feuille de route</1> pour voir quand ce problème devrait être résolu!',
  DownloadTitle: 'Télécharger',
  DownloadTheHardWay: 'À la dure',
  DownloadTheHardWayDescription: 'Enfin, dans le cas où vous savez ce que vous faites, vous pouvez suivre <1>la page wiki "Compilation"</1> et compiler/installer chaque parties manuellement.',
  DownloadWarningKernelFreeze: '<1>Attention:</1> malheureusement le projet souffre d\'un bug de gel du noyau qui peut casser votre machine!',
  DownloadWhatAboutPackages: 'Et les packages de distribution?',
  // GitterBadge
  GitterBadgeTitle: 'Rejoiniez le salon de discussion de la communauté!',
  // Header
  HeaderDescription: 'Douane est un pare-feu qui protège la vie privée des utilisateurs en leur permettant de contrôler les applications qui peuvent se connecter à Internet depuis leur systeme GNU/Linux.',
  HeaderTitle: 'Pare-feu personnel Douane pour GNU / Linux',
  // HowCanIHelp
  HowCanIHelpIfYouKnowCDescription: 'Vous pouvez être très utile en stabilisant, optimisant, déboguant et refactorisant <1>le code Douane DKMS</1> qui est le cœur de Douane.',
  HowCanIHelpIfYouKnowCppDouaneDaemon: 'Vous pourrez alors aider à optimiser <1>le démon de Douane</1> mais aussi à implémenter de nouvelles fonctionnalités!',
  HowCanIHelpIfYouKnowCppDouaneDialog: 'La boîte de dialogue de Douane, qui informe l\'utilisateur des applications inconnues qui tentent d\'accéder au monde extérieur, et leur demande de l\'autoriser ou non, est également écrit en C++.',
  HowCanIHelpIfYouKnowCppDouaneDialogGtkmm: 'Vous aurez besoin de connaître <1>GTK et GTKmm</1> pour mettre à jour son apparence ou afin d\'ajouter de nouvelles fonctionnalités.',
  HowCanIHelpIfYouKnowCppKnowaboutDbus: 'Tout d\'abord, assurez-vous d\'avoir <1>étudié D-Bus</1>, qui est l\'épine dorsale de Douane.',
  HowCanIHelpIfYouKnowCppTitle: 'Si vous connaissez le langage de programmation C++',
  HowCanIHelpIfYouKnowCTitle: 'Si vous connaissez le langage de programmation C',
  HowCanIHelpIfYouKnowJsAndReactDescription: 'Vous pouvez améliorer ce site Web et suggérer des modifications en ouvrant des tickets ou en fusionnant des demandes dans <1>son dépôt</1>.',
  HowCanIHelpIfYouKnowJsAndReactTitle: 'Si vous connaissez le langage de programmation JavaScript et React',
  HowCanIHelpIfYouKnowPackagingProcess: 'Aller voir si vous pouvez aider dans <1>un ticket existant</1> ou ouvrez-en un nouveau afin d\'obtenir de l\'aide si vous en avez besoin.',
  HowCanIHelpIfYouKnowPackagingProcess2: 'Ou <1>ouvrez-en un nouveau</1> afin d\'obtenir de l\'aide si vous en avez besoin.',
  HowCanIHelpIfYouKnowPackagingTitle: 'Si vous savez emballer les applications',
  HowCanIHelpIfYouKnowPackagingWelcome: 'Vous êtes les bienvenus pour rendre le projet populaire et accessible à toutes les personnes qui ne sont pas des techniciens.',
  HowCanIHelpIfYouKnowPythonDouaneConfiguratorDbus: 'Vous devrez également <1>connaître D-Bus</1> afin de communiquer avec le démon de Douane.',
  HowCanIHelpIfYouKnowPythonDouaneConfiguratorPanel: '<1>Le panneau de configuration de Douane</1>, qui donne le contrôle de Douane aux utilisateurs, est écrit en Python 3 et nécessite également des améliorations et de nouvelles fonctionnalités.',
  HowCanIHelpIfYouKnowPythonTitle: 'Si vous connaissez le langage de programmation Python',
  HowCanIHelpIfYouAreATranslaterMoreToCome: 'D\'autres projets où les traductions peuvent être mises à jour viendront avec le temps.',
  HowCanIHelpIfYouAreATranslaterProjectList: 'Vous pouvez aider à traduire les projets suivants:',
  HowCanIHelpIfYouAreATranslaterTitle: 'Si vous êtes traducteur',
  HowCanIHelpStillWantToHelpOpenIssues: 'Vous pouvez ouvrir des tickets concernant les bugs que vous trouvez ou de nouvelles fonctionnalités qui pourraient améliorer Douane.',
  HowCanIHelpStillWantToHelpAllFeedbacksAreWelcome: 'Tous les retours sont les bienvenus bien sûr!',
  HowCanIHelpStillWantToHelpThanksFirst: 'Tout d\'abord, laissez-moi vous remercier pour votre motivation!',
  HowCanIHelpStillWantToHelpTitle: 'Vous voulez toujours aider?',
  HowCanIHelpTitle: 'Comment puis-je aider?',
  // MeetTheDouaneDev
  MeetTheDouaneDevAndImproveKnowledge: 'Mais je l\'ai fait aussi pour améliorer mes connaissances en développement dans les différents languages que compose le projet.',
  MeetTheDouaneDevMyExperience: 'Je suis le développeur principal derrière Douane avec de nombreuses années d\'expérience en C/C++, Ruby, Python et d\'autres.',
  MeetTheDouaneDevTitle: 'Rencontrez le développeur de Douane',
  MeetTheDouaneDevWhyDouane: 'J\'ai développé Douane, pendant mon temps libre, car j\'avais besoin de ce type d\'outil qui existe sur les autres systèmes d\'exploitation, mais pas pour Linux.',
  // Roadmap
  RoadmapDescription: 'Dans cette section, vous pouvez avoir un aperçu de la feuille de route du projet.',
  RoadmapTitle: 'Feuille de route'
}

export default FrenchTranslation
