import React from 'react'
import {
  Alert
} from 'reactstrap'
import DouaneInstaller from '../images/DouaneInstaller.png'
import { Trans } from 'react-i18next'

const Download = () => (
  <React.Fragment>
    <h2
      className="text-center"
      id="download"
    >
      <Trans i18nKey="DownloadTitle">
        Download
      </Trans>
    </h2>

    <Alert color="warning">
      <Trans i18nKey="DownloadWarningKernelFreeze">
        {/*react-i18next seem to be buggy with a sentence starting with an HTML
        ** tag like this <strong> here. Passing the space at the beginning with
        *  &nbsp; does seem to workaround it.
        */}
        &nbsp;<strong>Warning:</strong>
        &nbsp;unfortunately the project is suffering of a kernel freeze bug that
        can break your machine!
      </Trans>
      <br />
      <br />
      <Trans i18nKey="DownloadFollowupOnIssue">
        You can follow the bug resolution from&nbsp;
        <a
          href="https://gitlab.com/douaneapp/douane-dkms/-/issues/3"
          rel="noopener noreferrer"
          target="_blank"
        >
          this issue
        </a>.
      </Trans>
      <br />
      <Trans i18nKey="DownloadSeeRoadmap">
        Have a look at the&nbsp;
        <a href="#roadmap">
          Roadmap
        </a>
        &nbsp;to see when this issue should be fixed!
      </Trans>
    </Alert>

    <p className="lead mt-4">
      <Trans i18nKey="DownloadCurrentVersion">
        The current version is {{ version: '0.8.2' }}.
      </Trans>
    </p>

    <h3
      className="h4"
      id="douane-installer"
    >
      <Trans i18nKey="DownloadDouaneInstaller">
        douane-installer (Recommended)
      </Trans>
    </h3>
    <p>
      <Trans i18nKey="DownloadDouaneInstallerIsFlexible">
        {/*react-i18next seem to be buggy with a sentence starting with an HTML
        ** tag like this <strong> here. Passing the space at the beginning with
        *  &nbsp; does seem to workaround it.
        */}
        &nbsp;<a
          href="https://gitlab.com/douaneapp/Douane/-/wikis/Architecture"
          rel="noopener noreferrer"
          target="_blank"
        >
          The Douane architecture
        </a>
        &nbsp;is a puzzle, on purpose, in order to allow Douane to fit in the
        giant Linux ecosystem.
      </Trans>
      <br />
      <Trans i18nKey="DownloadDouaneInstallerThePriceOfFlexibility">
        But flexibility comes with complexity and makes Douane hard to install for
        many people.
      </Trans>
      <br />
      <br />
      <Trans i18nKey="DownloadDouaneInstallerComesIn">
        That's why I created&nbsp;
        <a
          href="https://gitlab.com/douaneapp/douane-installer"
          rel="noopener noreferrer"
          target="_blank"
        >
          douane-installer
        </a>
        &nbsp;which automatises the download, compilation and installation of
        Douane on your machine.
      </Trans>
    </p>

    <div className="d-flex justify-content-center my-4">
      <figure className="figure w-100 w-md-50">
        <img
          alt="Douane installer screenshot"
          className="figure-img img-fluid rounded"
          src={DouaneInstaller}
        />
        <figcaption className="figure-caption text-center">
          <Trans i18nKey="DownloadDouaneInstallerFigureCaption">
            douane-installer automatise the Douane installation process.
          </Trans>
        </figcaption>
      </figure>
    </div>

    <p>
      <Trans i18nKey="DownloadDouaneInstallerDownloadItNow">
        Download it, and run it as described in&nbsp;
        <a
          href="https://gitlab.com/douaneapp/douane-installer/-/blob/master/README.md"
          rel="noopener noreferrer"
          target="_blank"
        >
          the project's README.md file
        </a>.
      </Trans>
    </p>

    <h3
      className="h4 mt-5"
      id="the-hard-way"
    >
      <Trans i18nKey="DownloadTheHardWay">
        The hard way
      </Trans>
    </h3>
    <p>
      <Trans i18nKey="DownloadTheHardWayDescription">
        Finally, in the case you know what you are doing, you can follow&nbsp;
        <a
          href="https://gitlab.com/douaneapp/Douane/-/wikis/Compilation"
          rel="noopener noreferrer"
          target="_blank"
        >
          the compilation wiki page
        </a>
        &nbsp;and compile/install each parts manually.
      </Trans>
    </p>

    <h3
      className="h4 mt-5"
      id="what-about-distribution-packages"
    >
      <Trans i18nKey="DownloadWhatAboutPackages">
        What about distribution packages?
      </Trans>
    </h3>
    <p>
      <Trans i18nKey="DownloadNoPackagesYet">
        Unfortunately the project is not packaged in any distribution format.
      </Trans>
      <br />
      <br />
      <Trans i18nKey="DownloadIAmNotDoingThePackaging">
        Packaging an app takes a lot of time, therefore I decided to not take
        care of this part, even do I was doing it in the past for the Debian
        package format.
      </Trans>
      <br />
      <i>
        <Trans i18nKey="DownloadFindDebianPackageConfigFiles">
          You can find some files&nbsp;
          <a
            href="https://gitlab.com/douaneapp/douane-debian"
            rel="noopener noreferrer"
            target="_blank"
          >
            here
          </a>.
        </Trans>
      </i>
      <br />
      <br />
      <Trans i18nKey="DownloadAnotherReason">
        Another reason is because of the low popularity of the project since it
        has&nbsp;
        <a
          href="https://gitlab.com/douaneapp/douane-dkms/issues/3"
          rel="noopener noreferrer"
          target="_blank"
        >
          this bad bug about kernel freezes
        </a>
        &nbsp;which is the top priority in the entire project.
      </Trans>
    </p>
  </React.Fragment>
)

export default Download
