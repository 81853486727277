import React from 'react'
import { Badge } from 'reactstrap'

const BugBadge = () => (
  <Badge
    className="d-flex flex-fill justify-content-center align-items-center"
    color="danger"
  >
    <span className="h6 my-1">Bug</span>
  </Badge>
)

export default BugBadge
