import React from 'react'
import {
  Col,
  ListGroupItem,
  Row
} from 'reactstrap'
import PropTypes from 'prop-types'

import BugBadge from './badges/Bug'
import EnhancementBadge from './badges/Enhancement'
import FeatureBadge from './badges/Feature'
import UnknownBadge from './badges/Unknown'

const Issue = ({ kind, project, title, url }) => {
  let badge = null

  switch (kind) {
    case 'bug':
      badge = <BugBadge />
      break
    case 'feature':
      badge = <FeatureBadge />
      break
    case 'enhancement':
      badge = <EnhancementBadge />
      break
    default:
      badge = <UnknownBadge />
  }

  return (
    <ListGroupItem>
      <Row>
        <Col
          lg="2"
          md="3"
          xs="4"
        >
          {badge}
        </Col>
        <Col
          className="d-none d-lg-flex align-items-center justify-content-end justify-content-lg-center"
          lg="3"
        >
          <span className="text-muted">{project}</span>
        </Col>
        <Col
          className="d-flex align-items-center p-0"
        >
          <a
            href={url}
            rel="noopener noreferrer"
            target="_blank"
          >
            {title}
          </a>
        </Col>
      </Row>
    </ListGroupItem>
  )
}

Issue.propTypes = {
  kind: PropTypes.oneOf(['bug', 'feature', 'enhancement']).isRequired,
  project: PropTypes.oneOf([
    'douane-dkms',
    'douane-daemon',
    'douane-dialog',
    'douane-configurator',
    'douane-installer'
  ]).isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
}

export default Issue
