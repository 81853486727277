import React from 'react'
import i18n from 'i18next';

const GitterBadge = () => (
  <a
    href='https://gitter.im/Douane/General?utm_source=badge&utm_medium=badge&utm_campaign=pr-badge'
    title={i18n.t('GitterBadgeTitle')}
  >
    <img src={'https://badges.gitter.im/Douane/General.svg'} alt="Gitter" />
  </a>
)

export default GitterBadge
