import React from 'react'
import BuiltInFeature from './BuiltInFeature'
import DouaneDialogImage from '../images/DouaneDialog.png'
import { Trans } from 'react-i18next'

const DouaneDialog = () => (
  <BuiltInFeature
    figureAlt="Douane Dialog window"
    figureSrc={DouaneDialogImage}
    caption={(
      <React.Fragment>
        <h3>
          <Trans i18nKey="DouaneDialogTitle">
            Simple as answering a question
          </Trans>
         </h3>
        <p>
          <Trans i18nKey="DouaneDialogAppAccessingTheInternet">
            As soon as you have access to a network, applications will try to
            send whatever information.
          </Trans>
        </p>
        <p>
          <Trans i18nKey="DouaneDialogUnexpecedTrafic">
            Behind your traffic (emails, social networks, online videos, ...)
            you will discover some network activities that you did not expected.
          </Trans>
        </p>
        <p>
          <Trans i18nKey="DouaneDialogHowDoesItWork">
            The Douane firewall will blocks all the unknown traffic and let you
            decide if you allow it or not thanks to this dialog box.
          </Trans>
          <br />
          <Trans i18nKey="DouaneDialogClickToAddRule">
            Clicking the
            &nbsp;
            <strong>Allow</strong>
            &nbsp;or&nbsp;
            <strong>Deny</strong>
            &nbsp;buttons will create the desired rule for you.
          </Trans>
        </p>
      </React.Fragment>
    )}
  />
)

export default DouaneDialog
