import React from 'react'
import DouaneConfigurator from './DouaneConfigurator'
import DouaneDialog from './DouaneDialog'
import { Trans } from 'react-i18next'

const BuiltInFeaturesAvailable = () => (
  <div className="col mt-5">
    <h2
      className="text-center"
      id="built-in-features"
    >
      <Trans i18nKey="BuiltInFeaturesAvailableTitle">
        The built-in features available in Douane
      </Trans>
    </h2>

    <DouaneDialog />
    <DouaneConfigurator />
  </div>
)

export default BuiltInFeaturesAvailable
