import React from 'react'
import { Trans } from 'react-i18next'

const Compatibility = () => (
  <React.Fragment>
    <h2
      className="text-center"
      id="compatibility"
    >
      <Trans i18nKey="CompatibilityTitle">
        Compatibility
      </Trans>
    </h2>
    <p className="mt-5">
      <Trans i18nKey="CompatibilityDescription">
        Douane runs on any GNU/Linux distributions having a kernel version 3 or
        higher, and it requires Python 3 for the configuration panel, and GTK 3
        for the dialog and the configuration panel too.
      </Trans>
    </p>
    <p>
      <Trans i18nKey="CompatibilityFindCompleteList">
        You can find the complete Douane dependency list in&nbsp;
        <a
          href="https://gitlab.com/douaneapp/Douane/-/wikis/Dependencies"
          rel="noopener noreferrer"
          target="_blank"
        >
          the Douane Wiki page
        </a>.
      </Trans>
    </p>
  </React.Fragment>
)

export default Compatibility
