import React from 'react'
import { Trans } from 'react-i18next'

import VersionSeparator from './VersionSeparator'

import Version090 from './versions/0.9.0'
import Version0100 from './versions/0.10.0'

const Roadmap = () => (
  <React.Fragment>
    <h2
      className="text-center"
      id="roadmap"
    >
      <Trans i18nKey="RoadmapTitle">
        Roadmap
      </Trans>
    </h2>

    <p className="lead text-center">
      <Trans i18nKey="RoadmapDescription">
        In this section you can have an overview of the project's roadmap.
      </Trans>
    </p>

    <Version090 />
    <VersionSeparator />
    <Version0100 />
  </React.Fragment>
)

export default Roadmap
