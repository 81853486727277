import React from 'react'
import PropTypes from 'prop-types'
import './BuiltInFeature.scss'

const BuiltInFeature = ({ caption, figureAlt, figureSrc }) => (
  <figure className="d-flex flex-column flex-md-row figure mt-md-5 BuiltInFeature">
    <div className="d-flex w-100 w-md-50">
      <img
        alt={figureAlt}
        className="figure-img"
        src={figureSrc}
      />
    </div>
    <figcaption className="d-flex w-100 w-md-50 flex-column figure-caption ml-md-5">
      {caption}
    </figcaption>
  </figure>
)

BuiltInFeature.propTypes = {
  figureAlt: PropTypes.string.isRequired,
  figureSrc: PropTypes.node.isRequired,
  caption: PropTypes.node.isRequired
}

export default BuiltInFeature
