import React from 'react'
import {
  Media
} from 'reactstrap'
import { Trans } from 'react-i18next'

import {
  FaGithub,
  FaTwitter
} from 'react-icons/fa'
import {
  FiGitlab
} from 'react-icons/fi'

import ZedtuxAvatar from '../images/ZedtuxAvatar.png'

const MeetTheDouaneDev = () => (
  <React.Fragment>
    <h2
      className="text-center"
      id="meet-the-dev"
    >
      <Trans i18nKey="MeetTheDouaneDevTitle">
        Meet the developer of Douane
      </Trans>
    </h2>

    <div className="d-flex d-sm-none justify-content-center mt-5">
      <img
        alt="Zedtux's avatar"
        className="rounded"
        src={ZedtuxAvatar}
      />
    </div>

    <Media className="mt-5">
      <Media left>
        <Media object
          alt="Zedtux's avatar"
          className="rounded mr-3 d-none d-sm-block"
          src={ZedtuxAvatar}
        />
      </Media>
      <Media body>
        <Media heading>
          Guillaume Hain
          <a
            alt="zedtux on Twitter"
            className="ml-3"
            href="https://twitter.com/zedtux"
            rel="noopener noreferrer"
            target="_blank"
            title="zedtux on Twitter"
          >
            <FaTwitter />
          </a>
          <a
            alt="zedtux on Gitlab"
            className="ml-1"
            href="https://gitlab.com/zedtux"
            rel="noopener noreferrer"
            target="_blank"
            title="zedtux on Gitlab"
          >
            <FiGitlab />
          </a>
          <a
            alt="zedtux on Github"
            className="ml-1"
            href="https://github.com/zedtux"
            rel="noopener noreferrer"
            target="_blank"
            title="zedtux on Github"
          >
            <FaGithub />
          </a>
        </Media>
        <p className="mt-3 mt-sm-0">
          <Trans i18nKey="MeetTheDouaneDevMyExperience">
            I am the main developer behind Douane with many years of experience in
            C/C++, Ruby, Python and some more.
          </Trans>
          <br />
          <br />
          <Trans i18nKey="MeetTheDouaneDevWhyDouane">
            I have developed Douane, during my free time, because I need this
            kind of tool which exist on other operating systems, but don't for
            Linux.
          </Trans>
          <br />
          <br />
          <Trans i18nKey="MeetTheDouaneDevAndImproveKnowledge">
            But I did it also in order to improve my knowledge in development
            and with all the different languages which are used in this projet.
          </Trans>
          <br />
        </p>
      </Media>
    </Media>
  </React.Fragment>
)

export default MeetTheDouaneDev
