import React from 'react'
import {
  ListGroup
} from 'reactstrap'

import Issue from '../Issue'

const Version0100 = () => (
  <React.Fragment>
    <h3 className="h5">Version 0.10.0</h3>

    <ListGroup>
      <Issue
        kind="enhancement"
        project="douane-daemon"
        title="Daemon process using too much resources"
        url="https://gitlab.com/douaneapp/douane-daemon/-/issues/6"
      />
      <Issue
        kind="enhancement"
        project="douane-daemon"
        title="Write the help message"
        url="https://gitlab.com/douaneapp/douane-daemon/-/issues/2"
      />
      <Issue
        kind="feature"
        project="douane-daemon"
        title="Don't stop the daemon anymore, but disconnect from the kernel module instead"
        url="https://gitlab.com/douaneapp/douane-daemon/-/issues/15"
      />
      <Issue
        kind="bug"
        project="douane-dialog"
        title="Dialog crashes when multiple processes trigger simultaneously"
        url="https://gitlab.com/douaneapp/douane-dialog/-/issues/13"
      />
      <Issue
        kind="enhancement"
        project="douane-dialog"
        title="KDE implementation needed"
        url="https://gitlab.com/douaneapp/douane-dialog/-/issues/10"
      />
      <Issue
        kind="enhancement"
        project="douane-dialog"
        title="Logging place should be changed"
        url="https://gitlab.com/douaneapp/douane-dialog/-/issues/16"
      />
      <Issue
        kind="enhancement"
        project="douane-configurator"
        title="Enable/Disable switch not checking"
        url="https://gitlab.com/douaneapp/douane-configurator/-/issues/11"
      />
    </ListGroup>
  </React.Fragment>
)

export default Version0100
