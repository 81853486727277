import React from 'react'
import { Badge } from 'reactstrap'

const Enhancement = () => (
  <Badge
    className="d-flex flex-fill justify-content-center align-items-center"
    color="info"
  >
    <span className="h6 my-1 fs-xs-50">Enhancement</span>
  </Badge>
)

export default Enhancement
