import React from 'react'
import { Badge } from 'reactstrap'

const Feature = () => (
  <Badge
    className="d-flex flex-fill justify-content-center align-items-center"
    color="success"
  >
    <span className="h6 my-1">Feature</span>
  </Badge>
)

export default Feature
