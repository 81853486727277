import React from 'react'
import {
  ListGroup
} from 'reactstrap'

import Issue from '../Issue'

const Version090 = () => (
  <React.Fragment>
    <h3 className="h5">Version 0.9.0</h3>

    <ListGroup>
      <Issue
        kind="bug"
        project="douane-dkms"
        title="Kernel panic in Ubuntu 14.04"
        url="https://gitlab.com/douaneapp/douane-dkms/-/issues/3"
      />
      <Issue
        kind="bug"
        project="douane-dkms"
        title="netfiler_packet_hook: Something prevent to sent the network activity"
        url="https://gitlab.com/douaneapp/douane-dkms/-/issues/35"
      />
      <Issue
        kind="enhancement"
        project="douane-dkms"
        title="Replace printk by pr_* macros"
        url="https://gitlab.com/douaneapp/douane-dkms/-/issues/40"
      />
      <Issue
        kind="enhancement"
        project="douane-configurator"
        title="Update the enable/disable switch in order to support systemd"
        url="https://gitlab.com/douaneapp/douane-configurator/-/issues/30"
      />
      <Issue
        kind="enhancement"
        project="douane-installer"
        title="Install the lastest version instead of the master one"
        url="https://gitlab.com/douaneapp/douane-installer/-/issues/2"
      />
    </ListGroup>
  </React.Fragment>
)

export default Version090
