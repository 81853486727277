import React from 'react'
import { Trans } from 'react-i18next'

const HowCanIHelp = () => (
  <React.Fragment>
    <h2
      className="text-center"
      id="how-can-i-help"
    >
      <Trans i18nKey="HowCanIHelpTitle">
        How can I help?
      </Trans>
    </h2>

    <h3
      className="h5 mt-5"
      id="if-you-know-c"
    >
      <Trans i18nKey="HowCanIHelpIfYouKnowCTitle">
        If you know the C programming language
      </Trans>
    </h3>
    <p>
      <Trans i18nKey="HowCanIHelpIfYouKnowCDescription">
        You can be very helpful by stabilizing, optimizing, debugging and
        refactoring&nbsp;
        <a
          href="https://gitlab.com/douaneapp/douane-dkms"
          rel="noopener noreferrer"
          target="_blank"
        >
          the Douane DKMS code
        </a>
        &nbsp;which is the heart of Douane.
      </Trans>
    </p>

    <h3
      className="h5 mt-5"
      id="if-you-know-cpp"
    >
      <Trans i18nKey="HowCanIHelpIfYouKnowCppTitle">
        If you know the C++ programming language
      </Trans>
    </h3>
    <p>
      <Trans i18nKey="HowCanIHelpIfYouKnowCppKnowaboutDbus">
        First be sure to&nbsp;
        <a
          href="https://www.freedesktop.org/wiki/Software/dbus/"
          rel="noopener noreferrer"
          target="_blank"
        >
          know about D-Bus
        </a>
        , which is the backbone of Douane.
      </Trans>
      <Trans i18nKey="HowCanIHelpIfYouKnowCppDouaneDaemon">
        &nbsp;Then you can help on optimizing&nbsp;
        <a
          href="https://gitlab.com/douaneapp/douane-daemon"
          rel="noopener noreferrer"
          target="_blank"
        >
          the Douane daemon
        </a>
        &nbsp;but also implementing new features!
      </Trans>
      <br />
      <br />
      <Trans i18nKey="HowCanIHelpIfYouKnowCppDouaneDialog">
        The&nbsp;
        <a
          href="https://gitlab.com/douaneapp/douane-dialog"
          rel="noopener noreferrer"
          target="_blank"
        >
          Douane dialog
        </a>
        , that inform the user about unknown applications, trying to access
        the outworld, and ask them if they allow or deny it is written in C++
        too.
      </Trans>
      <br />
      <Trans i18nKey="HowCanIHelpIfYouKnowCppDouaneDialogGtkmm">
        You will need to know about&nbsp;
        <a
          href="https://gtkmm.org"
          rel="noopener noreferrer"
          target="_blank"
        >
          GTK and GTKmm
        </a>
        &nbsp;in order to update its look & feel or add new features.
      </Trans>
    </p>

    <h3
      className="h5 mt-5"
      id="if-you-know-python"
    >
      <Trans i18nKey="HowCanIHelpIfYouKnowPythonTitle">
        If you know the Python programming language
      </Trans>
    </h3>
    <p>
      <Trans i18nKey="HowCanIHelpIfYouKnowPythonDouaneConfiguratorPanel">
        {/*react-i18next seem to be buggy with a sentence starting with an HTML
        ** tag like this <strong> here. Passing the space at the beginning with
        *  &nbsp; does seem to workaround it.
        */}
        &nbsp;<a
          href="https://gitlab.com/douaneapp/douane-configurator"
          rel="noopener noreferrer"
          target="_blank"
        >
          The Douane configuration panel
        </a>
        , which gives the control of Douane to the users, is written in
        Python 3 and needs improvements and new features too.
      </Trans>
      <br />
      <br />
      <Trans i18nKey="HowCanIHelpIfYouKnowPythonDouaneConfiguratorDbus">
        You will need to&nbsp;
        <a
          href="https://www.freedesktop.org/wiki/Software/dbus/"
          rel="noopener noreferrer"
          target="_blank"
        >
          know about D-Bus
        </a>
        &nbsp;too in order to communicate with the Douane daemon.
      </Trans>
    </p>

    <h3
      className="h5 mt-5"
      id="if-you-know-packaging"
    >
      <Trans i18nKey="HowCanIHelpIfYouKnowPackagingTitle">
        If you know about packaging apps
      </Trans>
    </h3>
    <p>
      <Trans i18nKey="HowCanIHelpIfYouKnowPackagingWelcome">
        You are very welcome to make the project popular and accessible to all the
        people who are not technical people.
      </Trans>
      <br />
      <br />
      <Trans i18nKey="HowCanIHelpIfYouKnowPackagingProcess">
        Have a look at&nbsp;
        <a
          href="https://gitlab.com/douaneapp/Douane/-/issues?label_name%5B%5D=package"
          rel="noopener noreferrer"
          target="_blank"
        >
          existing issues
        </a>
        &nbsp;in order to help.
      </Trans>
      &nbsp;
      <Trans i18nKey="HowCanIHelpIfYouKnowPackagingProcess2">
        Or&nbsp;
        <a
          href="https://gitlab.com/douaneapp/Douane/-/issues/new"
          rel="noopener noreferrer"
          target="_blank"
        >
          open a new one
        </a>
        &nbsp;in order to get some help where you need it.
      </Trans>
    </p>

    <h3
      className="h5 mt-5"
      id="if-you-know-js-react"
    >
      <Trans i18nKey="HowCanIHelpIfYouKnowJsAndReactTitle">
        If you know the JavaScript programming language and React
      </Trans>
    </h3>
    <p>
      <Trans i18nKey="HowCanIHelpIfYouKnowJsAndReactDescription">
        You can improve this website and suggest modifications by opening issues
        or merge requests in&nbsp;
        <a
          href="https://gitlab.com/douaneapp/Douane/-/issues?label_name%5B%5D=package"
          rel="noopener noreferrer"
          target="_blank"
        >
          its repository
        </a>.
      </Trans>
    </p>

    <h3
      className="h5 mt-5"
      id="if-you-translate"
    >
      <Trans i18nKey="HowCanIHelpIfYouAreATranslaterTitle">
        If you are a translater
      </Trans>
    </h3>
    <p>
      <Trans i18nKey="HowCanIHelpIfYouAreATranslaterProjectList">
        You can help on translations in the following projects:
      </Trans>
    </p>

    <ul>
      <li>
        <a
          href="https://gitlab.com/douaneapp/douane-installer"
          rel="noopener noreferrer"
          target="_blank"
        >
          douane-install
        </a>
      </li>
      <li>
        <a
          href="https://gitlab.com/douaneapp/douaneapp.gitlab.io"
          rel="noopener noreferrer"
          target="_blank"
        >
          douaneapp.com
        </a>
      </li>
      <li>
        <a
          href="https://gitlab.com/douaneapp/douane/wikis"
          rel="noopener noreferrer"
          target="_blank"
        >
          the Douane Wiki pages
        </a>
      </li>
    </ul>

    <i>
      <Trans i18nKey="HowCanIHelpIfYouAreATranslaterMoreToCome">
        More places where translations can be updated will come over time.
      </Trans>
    </i>

    <h3
      className="h5 mt-5"
      id="still-want-to-help"
    >
      <Trans i18nKey="HowCanIHelpStillWantToHelpTitle">
        Still want to help?
      </Trans>
    </h3>
    <p>
      <Trans i18nKey="HowCanIHelpStillWantToHelpThanksFirst">
        First, let me thank you for your motivation!
      </Trans>
    </p>
    <p>
      <Trans i18nKey="HowCanIHelpStillWantToHelpOpenIssues">
        You can open issues about bugs you find, or new featues you think that
        could improve Douane.
      </Trans>
      <br />
      <Trans i18nKey="HowCanIHelpStillWantToHelpAllFeedbacksAreWelcome">
        All the feedbacks is welcome of course!
      </Trans>
    </p>
  </React.Fragment>
)

export default HowCanIHelp
