import React from 'react'
import { ReactComponent as Logo } from '../images/DouaneLogo.svg'
import { Trans } from 'react-i18next'
import './Header.scss'

import GitterBadge from './GitterBadge'

const Header = () => (
  <div className="d-flex Header">
    <div className="d-flex flex-column flex-md-row align-items-center">
      <Logo />
      <div className="d-flex flex-column text-center text-md-left">
        <div className="d-flex">
          <h1 className="h3">
            <Trans i18nKey="HeaderTitle">
              Douane personal firewall for GNU/Linux
            </Trans>
          </h1>
        </div>
        <div className="d-flex">
          <p>
            <Trans i18nKey="HeaderDescription">
              Douane is a personal firewall that protects a user\'s privacy by
              allowing a user to control which applications can connect to the
              internet from their GNU/Linux computer.
            </Trans>
          </p>
        </div>
        <GitterBadge />
      </div>
    </div>
  </div>
)

export default Header
