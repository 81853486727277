import React from 'react'
import { Badge } from 'reactstrap'

const Unknown = () => (
  <Badge
    className="d-flex flex-fill justify-content-center align-items-center"
    color="secondary"
  >
    <span className="h6 my-1">Unknown</span>
  </Badge>
)

export default Unknown
