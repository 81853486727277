import React from 'react'
import { Container } from 'reactstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import './bootstrap_extensions.scss'

import BuiltInFeaturesAvailable from './components/BuiltInFeaturesAvailable'
import Compatibility from './components/Compatibility'
import Download from './components/Download'
import Header from './components/Header'
import HeaderSpacer from './components/HeaderSpacer'
import HowCanIHelp from './components/HowCanIHelp'
import MeetTheDouaneDev from './components/MeetTheDouaneDev'
import Roadmap from './components/Roadmap'

const App = () => (
  <React.Fragment>
    <Container fluid>
      <HeaderSpacer>
        <Header />
      </HeaderSpacer>
    </Container>
    <Container>
      <BuiltInFeaturesAvailable />
      <hr />
      <Compatibility />
      <hr />
      <Download />
      <hr />
      <Roadmap />
      <hr />
      <HowCanIHelp />
      <hr />
      <MeetTheDouaneDev />
    </Container>
    <Container className="mb-7" />
  </React.Fragment>
)

export default App
